<!-- eslint-disable vue/no-template-key -->
<template>
  <!-- BEGIN: Content-->
  <div>
    <div
      id="offcanvasRetrieveApplicant"
      class="offcanvas offcanvas-end offcanvas-end--filters d-flex w-90"
      tabindex="-1"
      :class="{ show: visible }"
      :style="{ visibility: visible ? 'visible' : 'hidden' }"
      aria-labelledby="offcanvasRetrieveApplicantLabel"
    >
      <div class="offcanvas-header">
        <h3
          id="offcanvasRetrieveApplicantLabel"
          class="offcanvas-title"
        >
          <span class="btn btn-icon btn-icon rounded-circle btn-flat-secondary bg-light-secondary me-2"><i data-feather="thumbs-up" /></span> Retrieve applicant
        </h3>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          @click="closeOffcanva"
        />
      </div>
      <div class="offcanvas-body">
        <div
          v-if="!loading"
          class="table-responsive-sm"
        >
          <table class="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Institution</th>
                <th>Retrieve</th>
                <th>Answer</th>
                <th class="text-end">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="applicant in applicants"
              >
                <tr :key="`a-${applicant.user_id}`">
                  <td>{{ applicant.full_name }}</td>
                  <td>{{ applicant.institution_name }}</td>
                  <td><span
                    v-if="applicant.retrieve.id"
                    class="badge badge-light-success"
                  >YES</span></td>
                  <th>
                    <span
                      v-if="applicant.retrieve && applicant.retrieve.answer === true "
                      class="badge badge-light-success"
                    >ACCEPTED</span>
                    <span
                      v-if="applicant.retrieve && applicant.retrieve.answer === false "
                      class="badge badge-light-danger"
                    >DENIED</span>
                  </th>
                  <td class="text-end">
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Submit reason"
                    >
                      <a
                        v-b-toggle="`collapseRetrieve-${applicant.user_id}`"
                        class="btn btn-icon rounded-pill btn-flat-secondary"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseReason1"
                      ><i data-feather="eye" /></a>
                    </span>
                  </td>
                </tr>
                <b-collapse
                  :id="`collapseRetrieve-${applicant.user_id}`"
                  :key="`b-${applicant.user_id}`"
                  tag="tr"
                >
                  <td colspan="4">
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >Reason (The reason is not mandatory)</label>
                      <textarea
                        v-model="applicant.retrieve.reason"
                        name=""
                        cols=""
                        rows="4"
                        class="form-control"
                      />
                      <!-- Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed, illum maxime est quae at eveniet quisquam, deserunt dicta accusantium rerum sapiente nesciunt nostrum nam sequi nulla iste! Alias, facilis expedita! -->
                    </div>
                    <button
                      v-if="!(applicant.retrieve && applicant.retrieve.id)"
                      class="btn btn-sm btn-success"
                      @click="sendRetrieve(applicant)"
                    >
                      Submit
                    </button>
                    <em
                      v-if="applicant.retrieve && applicant.retrieve.id"
                      class="float-end"
                    ><small>Send: {{ applicant.retrieve.created_at }}h</small></em>
                  </td>
                </b-collapse>
              </template>
            </tbody>
          </table>
        </div>
        <div
          v-if="loading"
          id="loading-bg"
        >
          <div
            class="loading"
          >
            <div class="effect-1 effects" />
            <div class="effect-2 effects" />
            <div class="effect-3 effects" />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="visible"
      class="offcanvas-backdrop fade"
      :class="{ show: visible }"
      @click="closeOffcanva"
    />
  </div>

  <!-- END: Content-->
</template>
<script>
import { mapGetters } from 'vuex'
import { BCollapse } from 'bootstrap-vue'
import Vue from 'vue'

export default {
  components: {
    BCollapse,
  },
  props: {
    activeTab: { type: Number, required: true },
  },
  data() {
    return {
      applicants: [],
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      visible: 'modals/convoRetrieveApplicant',
      convo: 'convo/convo',
    }),
  },
  async mounted() {
    const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/${this.convo.id}/get-round/${this.activeTab}?per_page=1000&onlyeval=true`)
    const applicants = Object.keys(resp.data.data).map(e => {
      resp.data.data[e] = { ...resp.data.data[e], full_name: `${resp.data.data[e].last_name_1 ?? ''} ${resp.data.data[e].last_name_2 ?? ''}, ${resp.data.data[e].first_name ?? ''}` }
      return resp.data.data[e]
    })

    this.applicants = applicants
    this.loading = false
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 200)
  },
  methods: {
    closeOffcanva() {
      this.$store.dispatch('modals/toggleConvoRetrieveApplicant', false)
    },
    setRetrieve(retrieve, applicant) {
      this.applicants[this.applicants.findIndex(e => e.id === applicant.id)].retrieve = retrieve
    },
    async sendRetrieve(applicant) {
      this.loading = true
      const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/create/create-retrieve`, {
        applicant_id: applicant.user_id,
        convo_id: this.convo.id,
        reason: applicant.retrieve.reason,
      })

      this.setRetrieve(resp.data.data, applicant)
      this.loading = false
      this.$toast.success('Retrieve successfully created!')
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
  },
}
</script>

<!-- eslint-disable vue/no-template-key -->
<template>
  <!-- BEGIN: Content-->
  <div>
    <div
      id="offcanvasRetrieveApplicant"
      class="offcanvas offcanvas-end offcanvas-end--filters d-flex w-90"
      tabindex="-1"
      :class="{ show: visible }"
      :style="{ visibility: visible ? 'visible' : 'hidden' }"
      aria-labelledby="offcanvasRetrieveApplicantLabel"
    >
      <div
        class="offcanvas-header"
      >
        <h3
          id="offcanvasEvaluationsLabel"
          class="offcanvas-title"
        >
          <small>{{ convo.title }}</small><br><strong>{{ applicant.full_name }}</strong>
        </h3>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          @click="closeOffcanva"
        />
      </div>
      <div class="offcanvas-body">
        <div class="viewform__actions--buttons mb-2">
          <a
            class="btn btn-sm btn-flat-secondary"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Display all blocks"
            @click="showAll = true"
          ><i data-feather="maximize-2" /> Display all</a>
          <a
            class="btn btn-sm btn-flat-secondary"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Collapse all blocks"
            @click="showAll = false"
          ><i data-feather="minimize-2" /> Collapse all</a>
        </div>

        <div
          v-for="evaluator in applicant.no_moderators"
          :key="evaluator.id"
          class="card viewform__card-evaluator"
        >
          <div
            v-b-toggle="`collapseMeeting-${evaluator.id}`"
            class="card-header"
          >
            <h3 class="cart-title d-flex justify-content-between w-90">
              <a data-action="collapse"><i
                data-feather="circle"
                class="text-success"
              /> {{ evaluator.name }}</a>

            </h3>
            <div class="heading-elements">
              <ul class="list-inline mb-0">
                <li><a data-action="collapse"><i data-feather="chevron-down" /></a></li>
              </ul>
            </div>
          </div>
          <b-collapse
            :id="`collapseMeeting-${evaluator.id}`"
            :key="`b-${applicant.user_id}`"
            :visible="showAll"
          >
            <div class="card-content ">
              <div class="card-body">
                <span class="row">
                  <div class="col-sm-4">
                    <p><strong>Strengths</strong></p>
                    <p>{{ evaluator.pivot.stregths }}</p>
                  </div>
                  <div class="col-sm-4">
                    <p><strong>Weaknesses</strong></p>
                    <p>{{ evaluator.pivot.weaknesses }}</p>
                  </div>
                  <div class="col-sm-4">
                    <p><strong>General comments</strong></p>
                    <p>{{ evaluator.pivot.general_comments }}</p>
                  </div>
                </span>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
    <div
      v-if="visible"
      class="offcanvas-backdrop fade"
      :class="{ show: visible }"
      @click="closeOffcanva"
    />
  </div>

  <!-- END: Content-->
</template>
<script>
import { mapGetters } from 'vuex'
import { BCollapse } from 'bootstrap-vue'

export default {
  components: {
    BCollapse,
  },
  props: {
    applicant: { type: Object, required: true },
  },
  data() {
    return {
      showAll: true,
    }
  },
  computed: {
    ...mapGetters({
      visible: 'modals/convoPanelMeeting',
      convo: 'convo/convo',
    }),
  },
  methods: {
    closeOffcanva() {
      this.$store.dispatch('modals/toggleConvoPanelMeeting', false)
    },
  },
}
</script>

<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                Evaluations {{ convo.title }}<br>
                <small
                  v-if="current_access == activeTab"
                  id="time-in"
                >Deadline: <strong class="text-danger">{{ convo.end_date_formatted }} {{ convo.end_hour_formatted }}</strong></small>
                <small
                  v-else-if="current_access > activeTab"
                  id="time-out"
                >The deadline for this round has ended. For any question or request send an email to <a
                  href="mailto:ssalvado@icrea.cat"
                  class="text-dark"
                >ssalvado@icrea.cat</a></small>
                <small
                  v-else-if="current_access < activeTab"
                  id="time-out"
                >This round is not open yet. For any question or request send an email to <a
                  href="mailto:ssalvado@icrea.cat"
                  class="text-dark"
                >ssalvado@icrea.cat</a></small>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              @click="downloadZip()"
            >
              <a
                class="btn btn-icon btn-icon rounded-circle btn-flat-secondary loop-loading p-0"
                :class="{'d-none': !exportLoading}"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Loading export..."
              ><i
                data-feather="loader"
              /></a>
              <span
                class="loop-loading"
                :class="{'d-none': exportLoading}"
              >
                <i
                  data-feather="file"
                  class="me-25"
                />
              </span>

              {{ !exportLoading ? 'Download zip with all' : 'Exporting applicants, it could take 1-2 minutes...' }}
            </a>
            <a
              v-if="convo.allow_retrieves"
              class="btn btn-icon btn-primary ms-1"
              @click="openRetrieves"
            ><i
              data-feather="thumbs-up"
              class="me-25"
            />Retrieve ...</a>
          </div>
        </div>
      </div>

      <div class="content-body content-body--senior-call">
        <p
          v-if="!$route.name == 'evaluator.calls.evaluations.senior'"
          class="mb-3"
        >
          <a
            :href="`${url}/show-pdf/academia_evaluation.pdf`"
            target="_blank"
            class="btn btn-sm btn-flat-secondary"
          ><i data-feather="info" /> Online evaluation instructions</a>
          <a
            :href="`${url}/show-pdf/evaluation_guide.pdf`"
            target="_blank"
            class="btn btn-sm btn-flat-secondary"
          ><i data-feather="info" /> Guidelines Evaluators</a>
          <a
            :href="`${url}/show-pdf/conflict_of_interest.pdf`"
            target="_blank"
            class="btn btn-sm btn-flat-secondary"
          ><i data-feather="info" /> Conflict of interest - definition</a>
        </p>
        <p
          v-if="$route.name == 'evaluator.calls.evaluations.senior'"
          class="mb-3"
        >
          <a
            :href="`${url}/show-pdf/online_evaluation_instructions.pdf`"
            target="_blank"
            class="btn btn-sm btn-flat-secondary"
          ><i data-feather="info" /> Online evaluation instructions</a>
          <a
            :href="`${url}/show-pdf/icrea_call_evaluation_guidelines.pdf`"
            target="_blank"
            class="btn btn-sm btn-flat-secondary"
          ><i data-feather="info" /> Guidelines Evaluators</a>
          <a
            :href="`${url}/show-pdf/conflict_of_interest.pdf`"
            target="_blank"
            class="btn btn-sm btn-flat-secondary"
          ><i data-feather="info" /> Conflict of interest - definition</a>
          <a
            v-if="current_access == 8 || current_access == 9"
            :href="`${url}/show-pdf/feedback_recommendations_senior.pdf`"
            target="_blank"
            class="btn btn-sm btn-flat-secondary"
          ><i data-feather="info" />Feedback recommendations</a>
        </p>
        <b-tabs
          v-if="convo.id"
          :show="!loading"
        >
          <b-tab
            v-if="convo.type.id != 1"
            :active="current_access == 5"
            @click="$store.dispatch('pagination/clearPage'), loadTab(5)"
          >
            <template #title>
              Round 0
              <span
                :class="{'d-none': current_access > 5}"
                class="badge bg-success ms-1"
              ><i data-feather="check" /></span>
              <span
                class="badge bg-success ms-1"
                :class="{'d-none': pendingApplicants != 0 || loading || current_access != 5}"
              ><i data-feather="check" /></span>
              <span
                :class="{'d-none': pendingApplicants == 0 || current_access != 5}"
                class="badge bg-danger ms-1"
              >{{ pendingApplicants }}</span>
            </template>
            <div class="row">
              <div
                v-if="!loading && current_access >= 5"
                class="col-12"
              >
                <CompleteTable
                  :sorted-field="sortedField"
                  :data="applicants"
                  :sort-mapping="sortMapping"
                  :title="'applicants in round zero'"
                  :total="totalApplicants"
                >
                  <template v-slot="{field}">
                    <td class="text-end">
                      <!-- al finalizar la ronda 0, el botón conflicto de interés se bloquea -->
                      <a
                        v-if="field.status.includes('Conflict')"
                        class="btn btn-icon rounded-pill btn-flat-secondary"
                        :class="{'disabled': current_access != activeTab}"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Delete conflict of interest"
                        @click="markInterestConflict(field.user_id, false)"
                      ><i data-feather="x" /></a>
                      <a
                        v-if="!field.status.includes('Conflict')"
                        class="btn btn-icon rounded-pill btn-flat-warning"
                        :class="{'disabled': current_access != activeTab}"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Conflict of interest"
                        @click="markInterestConflict(field.user_id, true)"
                      ><i data-feather="x-octagon" /></a>
                      <a
                        class="btn btn-icon rounded-pill btn-flat-secondary"
                        :class="{'disabled': field.status.includes('Conflict')}"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Evaluation"
                        :href="$router.resolve({ name: 'evaluator.calls.evaluations.form-report', query: {
                          user: field.id,
                          convo: convo.id,
                          round: activeTab,
                          page: page,
                          actAs: $route.query.actAs,
                        } }).href"
                        target="_blank"
                      ><i data-feather="eye" /></a>
                    </td>
                  </template>
                  <template #customfilters />
                </CompleteTable>
              </div>
              <div v-else />
            </div>
          </b-tab>
          <b-tab
            :active="current_access == 6"
            @click="$store.dispatch('pagination/clearPage'), loadTab(6)"
          >
            <template #title>
              1st Round
              <template v-if="current_access >= 6">
                <span
                  :class="{'d-none': current_access >= 6}"
                  class="badge bg-success ms-1"
                ><i data-feather="check" /></span>
                <span
                  class="badge bg-success ms-1"
                  :class="{'d-none': pendingApplicants != 0 || loading || current_access == 7}"
                ><i data-feather="check" /></span>
                <span
                  :class="{'d-none': pendingApplicants == 0 || current_access != 6}"
                  class="badge bg-danger ms-1"
                >{{ pendingApplicants }}</span>
              </template>
            </template>
            <div class="row">
              <div
                v-if="!loading && current_access >= 6"
                class="col-12"
              >
                <CompleteTable
                  :sorted-field="sortedField"
                  :data="applicants"
                  :sort-mapping="sortMapping"
                  :title="'applicants in 1st round'"
                  :total="totalApplicants"
                >
                  <template v-slot="{field }">
                    <td
                      v-if="convo.type.id != 1"
                      class="text-end"
                    >
                      <a
                        class="btn btn-icon rounded-pill btn-flat-secondary"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        target="_blank"
                        title="Go to evaluation applicant"
                        :href="$router.resolve({name: 'evaluator.calls.evaluations.form-report', query: {
                          user: field.id,
                          convo: convo.id,
                          round: activeTab,
                          page: page,
                          actAs: $route.query.actAs,
                        },}).href"
                      ><i data-feather="eye" /></a>
                    </td>
                    <td
                      v-else
                      class="text-end"
                    >
                      <!-- al finalizar la ronda 0, el botón conflicto de interés se bloquea -->
                      <a
                        v-if="field.status.includes('Conflict')"
                        class="btn btn-icon rounded-pill btn-flat-secondary"
                        :class="{'disabled': current_access != activeTab}"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Delete conflict of interest"
                        @click="markInterestConflict(field.user_id, false)"
                      ><i data-feather="x" /></a>
                      <a
                        v-if="!field.status.includes('Conflict')"
                        class="btn btn-icon rounded-pill btn-flat-warning"
                        :class="{'disabled': current_access != activeTab}"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Conflict of interest"
                        @click="markInterestConflict(field.user_id, true)"
                      ><i data-feather="x-octagon" /></a>
                      <a
                        class="btn btn-icon rounded-pill btn-flat-secondary"
                        :class="{'disabled': field.status.includes('Conflict')}"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        target="_blank"
                        title="Evaluation"
                        :href="$router.resolve({name: 'evaluator.calls.evaluations.form-report', query: {
                          user: field.id,
                          convo: convo.id,
                          round: activeTab,
                          page: page,
                          actAs: $route.query.actAs,
                        },}).href"
                      ><i data-feather="eye" /></a>
                    </td>
                  </template>
                  <template #customfilters />
                </CompleteTable>
              </div>
              <div
                v-else
                class="alert alert-warning"
              >
                <div class="alert-body">
                  <p class="text-dark">
                    Closed
                  </p>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab
            :active="current_access == 7"
            @click="$store.dispatch('pagination/clearPage'), loadTab(7)"
          >
            <template #title>
              2nd Round
              <template v-if="current_access >= 7">
                <span
                  :class="{'d-none': current_access >= 7}"
                  class="badge bg-success ms-1"
                ><i data-feather="check" /></span>
                <span
                  class="badge bg-success ms-1"
                  :class="{'d-none': pendingApplicants != 0 || loading}"
                ><i data-feather="check" /></span>
                <span
                  :class="{'d-none': pendingApplicants == 0 || current_access != 7}"
                  class="badge bg-danger ms-1"
                >{{ pendingApplicants }}</span>
              </template>
            </template>
            <div class="row">
              <div
                v-if="!loading && current_access >= 7"
                class="col-12"
              >
                <CompleteTable
                  :sorted-field="sortedField"
                  :data="applicants"
                  :sort-mapping="sortMapping"
                  :title="'applicants in 2nd round'"
                  :total="totalApplicants"
                >
                  <template v-slot="{field}">
                    <td class="text-end">
                      <a
                        class="btn btn-icon rounded-pill btn-flat-secondary"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        target="_blank"
                        title="Go to evaluation applicant"
                        :href="$router.resolve({name: 'evaluator.calls.evaluations.form-report', query: {
                          user: field.id,
                          convo: convo.id,
                          round: activeTab,
                          page: page,
                          actAs: $route.query.actAs,
                        },}).href"
                      ><i data-feather="eye" /></a>
                    </td>
                  </template>
                  <template #customfilters />
                </CompleteTable>
              </div>
              <div
                v-else
                class="alert alert-warning"
              >
                <div class="alert-body">
                  <p class="text-dark">
                    Closed
                  </p>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab
            :active="current_access == 8 || current_access == 9"
            @click="$store.dispatch('pagination/clearPage'), loadTab(8)"
          >
            <template #title>
              Feedback
              <template v-if="current_access >= 8 || current_access >= 9">
                <!-- <span
                  v-if="current_access != 8"
                  class="badge bg-success ms-1"
                ><i data-feather="check" /></span> -->
                <span
                  class="badge bg-success ms-1"
                  :class="{'d-none': pendingApplicants != 0 || loading}"
                ><i data-feather="check" /></span>
                <span
                  :class="{'d-none': pendingApplicants == 0 && !loading}"
                  class="badge bg-danger ms-1"
                ><i data-feather="check" /></span>
              </template>
            </template>
            <div class="row">
              <div
                v-if="!loading && current_access >= 7"
                class="col-12"
              >
                <div class="alert alert-warning">
                  <div class="alert-body">
                    <a
                      :href="`${url}/show-pdf/feedback_${convo.type.id == 1 ? 'recommendations_senior' : 'recomendations' }.pdf`"
                      class=""
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasFeedbackRecomendations"
                      aria-controls="offcanvasFeedbackRecomendations"
                    ><i
                      data-feather="info"
                      class="text-warning"
                    /> <span class="text-dark">Feedback recommendations</span></a>
                  </div>
                </div>
                <CompleteTable
                  :sorted-field="sortedField"
                  :data="applicants"
                  :sort-mapping="sortMapping"
                  :title="'applicants need your feedback'"
                  :total="totalApplicants"
                >
                  <template v-slot="{field}">
                    <td class="text-end">
                      <a
                        v-if="!field.status.includes('No yet available')"
                        class="btn btn-icon rounded-pill btn-flat-secondary"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Go to evaluation applicant"
                        :href="$router.resolve({name: 'evaluator.calls.evaluations.feedback-report', params: {
                          user: field,
                          convo: convo,
                          round: activeTab,
                        }, query: { actAs: $route.query.actAs } }).href"
                        target="_blank"
                      ><i data-feather="eye" /></a>
                    </td>
                  </template>
                  <template #customfilters />
                </CompleteTable>
              </div>
              <div
                v-else
                class="alert alert-warning"
              >
                <div class="alert-body">
                  <p class="text-dark">
                    The feedback editor is a member of the evaluations panel who is responsible for the feedback that will be sent to the candidate at the end of the process. Based on the comments posted by the rest of evaluators, the feedback editor puts together a short summary to be sent to the applicant. At the end of each evaluations round, your assigned applicants will be added below. Feedback can be entered as soon as the names appear, and can be edited as often as needed.
                  </p>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab
            :active="current_access == 10"
            @click="$store.dispatch('pagination/clearPage'), loadTab(10)"
          >
            <template #title>
              Panel meeting
            </template>
            <div class="row">
              <div
                v-if="!loading && current_access >= 10"
                class="col-12"
              >
                <CompleteTable
                  :sorted-field="sortedField"
                  :data="applicants"
                  :sort-mapping="sortMapping"
                  :title="'applicants in panel meeting'"
                  :total="totalApplicants"
                >
                  <template v-slot="{field}">
                    <td class="text-end">
                      <a
                        class="btn btn-icon rounded-pill btn-flat-secondary"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Go to evaluation applicant"
                        @click="selectItem(field)"
                      ><i data-feather="eye" /></a>
                    </td>
                  </template>
                  <template #customfilters />
                </CompleteTable>
              </div>
              <div
                v-else
                class="alert alert-warning"
              >
                <div class="alert-body">
                  <p><i
                    data-feather="alert-circle"
                    class="text-warning"
                  /> <span class="text-dark">This tab will hold all submitted evaluations by all panel members. it will be open just before the meeting.</span></p>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
        <div
          v-else-if="!loading && !message"
          class="alert alert-danger p-1"
        >
          You don't have any assigned active call
        </div>
        <div
          v-else-if="!loading && message"
          class="alert alert-danger p-1"
        >
          {{ message }}
        </div>
        <div
          v-if="loading"
          id="loading-bg"
        >
          <div
            class="loading"
          >
            <div class="effect-1 effects" />
            <div class="effect-2 effects" />
            <div class="effect-3 effects" />
          </div>
        </div>
      </div>
      <PanelMeetingUser :applicant="selectedItem" />
      <RetrieveApplicant
        v-if="!loading"
        :active-tab="activeTab"
      />
    </div>
    <TableColumns />
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import { mapGetters } from 'vuex'
import {
  BTab, BTabs,
} from 'bootstrap-vue'
import TableColumns from '../../partials/offcanvas/TableColumns.vue'
import CompleteTable from '../../partials/components/CompleteTable.vue'
import 'vue2-datepicker/index.css'
import RetrieveApplicant from './components/RetrieveApplicant.vue'
import PanelMeetingUser from './components/PanelMeetingUser.vue'

export default {
  components: {
    TableColumns,
    CompleteTable,
    BTab,
    BTabs,
    RetrieveApplicant,
    PanelMeetingUser,
  },
  data() {
    return {
      exportLoading: false,
      activeTab: null,
      selectedItem: {},
      applicants: [],
      sortedField: 'Researcher',
      defaultFields: [
        {
          name: 'Name',
          checked: true,
          order: 1,
        },
        {
          name: 'Area',
          checked: true,
          order: 2,
        },
        {
          name: 'ERC',
          checked: true,
          order: 3,
        },
        {
          name: 'Institution',
          checked: true,
          order: 4,
        },
        {
          name: 'Status',
          checked: true,
          order: 5,
        },
      ],
      defaultFieldsPanel: [
        {
          name: 'Name',
          checked: true,
          order: 1,
        },
        {
          name: 'Area',
          checked: true,
          order: 2,
        },
        {
          name: 'ERC',
          checked: true,
          order: 3,
        },
        {
          name: 'Institution',
          checked: true,
          order: 4,
        },
      ],
      sortMapping: {
        Name: 'full_name',
        Area: 'area',
        ERC: 'erc',
        Institution: 'institution_name',
        Status: 'status',
      },
      loading: true,
      totalApplicants: 0,
      currentQuery: null,
    }
  },
  computed: {
    ...mapGetters({
      evaluator: 'convo/evaluator',
      convo: 'convo/convo',
      message: 'convo/evaluatorConvoMessage',
      loggedUser: 'auth/admin',
      fields: 'modals/tableSettingsFields',
      page: 'pagination/page',
      perPage: 'pagination/perPage',
      pendingApplicants: 'convo/pendingApplicants',
      filters: 'filters/filters',
    }),
    url() {
      return Vue.prototype.$groupUrl
    },
    current_access() {
      return this.convo.current_access ? this.convo.current_access.type : null
    },
  },
  watch: {
    page() {
      this.getApplicants()
    },
    perPage() {
      this.getApplicants()
    },
  },
  async mounted() {
    if (this.$route.params.page) {
      await this.$store.dispatch('pagination/savePage', this.$route.params.page)
    }

    this.$store.dispatch('pagination/clearPage')
    await this.$store.dispatch('modals/fetchUserFields', 'convo_evaluation')
    await this.$store.dispatch('convo/fetchEvaluator', this.loggedUser.id)
    await this.$store.dispatch('convo/fetchAssignedConvo')

    if (this.convo.id) {
      this.loadTab(this.current_access)
    } else {
      this.loading = false
    }

    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'convo_evaluation',
      })
    }

    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    async getPendingApplicants() {
      await this.$store.dispatch('convo/fetchEvaluatorPendingApplicants', { user: this.loggedUser.id, round: this.activeTab, convoId: this.convo.id })
    },
    openRetrieves() {
      this.$store.dispatch('modals/toggleConvoRetrieveApplicant', true)
    },
    async downloadZip() {
      this.exportLoading = true

      await this.$store.dispatch('convo/downloadZipApplicants', {
        round: this.activeTab,
        convo: this.convo.id,
      })

      this.exportLoading = false
    },
    async loadTab(round) {
      if (round === 10 || round === '10') {
        await this.$store.dispatch('modals/fetchUserFields', 'panel_meeting')
        if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
          await this.$store.dispatch('modals/fetchFields', {
            fields: this.defaultFieldsPanel,
            table: 'panel_meeting',
          })
        }
      } else {
        await this.$store.dispatch('modals/fetchUserFields', 'convo_evaluation')
      }

      if (!this.convo.id) return
      this.activeTab = round
      this.loading = true

      if (this.current_access >= this.activeTab || (this.current_access >= 6 && (this.activeTab == 8 || this.activeTab == 9))) {
        this.getApplicants()
      } else {
        this.applicants = []
        this.loading = false
      }

      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    async getApplicants() {
      this.loading = true
      if (this.currentQuery) {
        this.currentQuery.cancel('Request canceled by user')
      }

      this.currentQuery = axios.CancelToken.source()

      const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/${this.convo.id}/get-round/${this.activeTab}`, {
        cancelToken: this.currentQuery.token,
      })

      const applicants = Object.keys(resp.data.data).map(e => {
        resp.data.data[e] = { ...resp.data.data[e], full_name: `${resp.data.data[e].last_name_1 ?? ''} ${resp.data.data[e].last_name_2 ?? ''}, ${resp.data.data[e].first_name ?? ''}` }
        return resp.data.data[e]
      })
      this.totalApplicants = resp.data.total
      this.applicants = applicants

      if (this.current_access == this.activeTab) {
        this.getPendingApplicants()
      }

      this.loading = false
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 150)
    },
    openTableSettings() {
      this.$store.dispatch('modals/toggleTableSettings', true)
    },
    saveFilter(value, field) {
      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
    async onSearch(search, store) {
      const s = search
      s.active = true
      if (s.name !== '') {
        await this.$store.dispatch(store, s)
      }
    },
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'grants/fetch', url: `${Vue.prototype.$groupUrl}/grants/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
    async markInterestConflict(userId, add) {
      if (this.current_access == this.activeTab) {
        const outside = this
        let title = ''

        if (add) {
          title = 'Do you want to set a conflict of interest with this applicant?'
        } else {
          title = 'Do you want to remove this conflict of interest from this applicant?'
        }

        Vue.swal({
          title,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes',
        }).then(result => {
          if (result.isConfirmed) {
            Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/${outside.convo.id}/evaluate-applicant`, {
              userId,
              interest_conflict: add,
            }).then(() => {
              outside.loadTab(outside.current_access)
            })
          }
        })
      }
    },
    selectItem(item) {
      this.selectedItem = item
      this.$store.dispatch('modals/toggleConvoPanelMeeting', true)
    },
  },
}
</script>
